<template>
    <video v-if="acfData.video" ref="videoRef" class="w-full h-auto mb-[5%]" muted autoplay>
        <source :src="acfData.video.url" type="video/mp4">
        Your browser does not support the video tag.
    </video>

    <div class="tv-wrapper">

        <div v-if="leaderboard.length > 0" class="text-center mb-[5%]">
            <p class="current-leader-text">Just nu är:</p>
            <p class="leader-text shadowed-text text-purple relative">
                <span class="name-highlight">
                    <svg width="84" height="97" viewBox="0 0 84 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M52.9199 62.7405L7.84772 39.8778M80.2222 49.3802L58.6588 3.67212M54.5 93.5001H3.5"
                            stroke="#A1ECDD" stroke-width="7" stroke-linecap="round" />
                    </svg>
                    {{ leaderboard[0].name }}
                    <svg width="85" height="97" viewBox="0 0 85 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.08 62.7404L77.1523 39.8776M4.32788 49.38L25.8913 3.672M30.5 93.5H81.5"
                            stroke="#A1ECDD" stroke-width="7" stroke-linecap="round" />
                    </svg>
                </span>
                smartast på Järvaveckan
            </p>
        </div>


        <div class="bg-pink p-[5%] rounded-[70px] mb-[5%]">
            <h2 style="font-size:calc(42px + 0.5vw);" class="text-[#EEE7FF] text-center font-medium">Topp 10 smartast på
                Järvaveckan
                idag</h2>
            <svg class="w-full mb-[5%]" viewBox="0 0 823 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H822L87.8365 22.25L666.747 26" stroke="#EEE7FF" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            <div class="flex flex-row" v-if="leaderboard">
                <ol class="w-1/2 list-inside">
                    <li v-for="(participant, index) in leaderboard.slice(0, 5)" style="font-size:calc(32px + 0.5vw);"
                        class="text-yellow font-medium mb-[2%]">{{ index + 1 }}. {{
                            participant.name }}
                    </li>
                </ol>
                <ol class="w-1/2 list-inside">
                    <li v-for="(participant, index) in leaderboard.slice(5, 10)" style="font-size:calc(32px + 0.5vw);"
                        class="text-yellow font-medium mb-[2%]">
                        {{ index + 6 }}. {{ participant.name }}
                    </li>
                </ol>
            </div>
        </div>

        <div class="flex flex-row gap-[2%] pb-[2%]">
            <div class="w-2/3 flex flex-col justify-center items-center">
                <h3 style="font-size:calc(37px + 0.5vw);" class="font-medium leading-[1.1] text-purple mb-[2%]">Är du
                    smartare?
                    Gör vårt
                    quiz och få
                    chansen att vinna
                    fina priser</h3>
            </div>
            <div class="w-1/3 flex flex-row items-center justify-center">
                <svg class="w-1/2" viewBox="0 0 491 491" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M176.78 0.539978H157.18V20.14H176.78V0.539978Z" fill="#E94591" />
                    <path d="M196.38 0.539978H176.78V20.14H196.38V0.539978Z" fill="#E94591" />
                    <path d="M255.17 0.539978H235.57V20.14H255.17V0.539978Z" fill="#E94591" />
                    <path d="M294.36 0.539978H274.76V20.14H294.36V0.539978Z" fill="#E94591" />
                    <path d="M333.55 0.539978H313.95V20.14H333.55V0.539978Z" fill="#E94591" />
                    <path d="M176.78 20.14H157.18V39.74H176.78V20.14Z" fill="#E94591" />
                    <path d="M235.57 20.14H215.97V39.74H235.57V20.14Z" fill="#E94591" />
                    <path d="M333.55 20.14H313.95V39.74H333.55V20.14Z" fill="#E94591" />
                    <path d="M196.38 39.73H176.78V59.33H196.38V39.73Z" fill="#E94591" />
                    <path d="M215.97 39.73H196.37V59.33H215.97V39.73Z" fill="#E94591" />
                    <path d="M255.17 39.73H235.57V59.33H255.17V39.73Z" fill="#E94591" />
                    <path d="M274.76 39.73H255.16V59.33H274.76V39.73Z" fill="#E94591" />
                    <path d="M176.78 59.33H157.18V78.93H176.78V59.33Z" fill="#E94591" />
                    <path d="M196.38 59.33H176.78V78.93H196.38V59.33Z" fill="#E94591" />
                    <path d="M235.57 59.33H215.97V78.93H235.57V59.33Z" fill="#E94591" />
                    <path d="M274.76 59.33H255.16V78.93H274.76V59.33Z" fill="#E94591" />
                    <path d="M313.96 59.33H294.36V78.93H313.96V59.33Z" fill="#E94591" />
                    <path d="M215.97 78.93H196.37V98.53H215.97V78.93Z" fill="#E94591" />
                    <path d="M255.17 78.93H235.57V98.53H255.17V78.93Z" fill="#E94591" />
                    <path d="M274.76 78.93H255.16V98.53H274.76V78.93Z" fill="#E94591" />
                    <path d="M294.36 78.93H274.76V98.53H294.36V78.93Z" fill="#E94591" />
                    <path d="M313.96 78.93H294.36V98.53H313.96V78.93Z" fill="#E94591" />
                    <path d="M333.55 78.93H313.95V98.53H333.55V78.93Z" fill="#E94591" />
                    <path d="M196.38 98.52H176.78V118.12H196.38V98.52Z" fill="#E94591" />
                    <path d="M215.97 98.52H196.37V118.12H215.97V98.52Z" fill="#E94591" />
                    <path d="M235.57 98.52H215.97V118.12H235.57V98.52Z" fill="#E94591" />
                    <path d="M294.36 98.52H274.76V118.12H294.36V98.52Z" fill="#E94591" />
                    <path d="M333.55 98.52H313.95V118.12H333.55V98.52Z" fill="#E94591" />
                    <path d="M176.78 118.12H157.18V137.72H176.78V118.12Z" fill="#E94591" />
                    <path d="M215.97 118.12H196.37V137.72H215.97V118.12Z" fill="#E94591" />
                    <path d="M255.17 118.12H235.57V137.72H255.17V118.12Z" fill="#E94591" />
                    <path d="M294.36 118.12H274.76V137.72H294.36V118.12Z" fill="#E94591" />
                    <path d="M333.55 118.12H313.95V137.72H333.55V118.12Z" fill="#E94591" />
                    <path d="M176.78 137.72H157.18V157.32H176.78V137.72Z" fill="#E94591" />
                    <path d="M215.97 137.72H196.37V157.32H215.97V137.72Z" fill="#E94591" />
                    <path d="M235.57 137.72H215.97V157.32H235.57V137.72Z" fill="#E94591" />
                    <path d="M255.17 137.72H235.57V157.32H255.17V137.72Z" fill="#E94591" />
                    <path d="M294.36 137.72H274.76V157.32H294.36V137.72Z" fill="#E94591" />
                    <path d="M313.96 137.72H294.36V157.32H313.96V137.72Z" fill="#E94591" />
                    <path d="M20.0099 157.31H0.409912V176.91H20.0099V157.31Z" fill="#E94591" />
                    <path d="M59.1999 157.31H39.5999V176.91H59.1999V157.31Z" fill="#E94591" />
                    <path d="M78.7999 157.31H59.1999V176.91H78.7999V157.31Z" fill="#E94591" />
                    <path d="M117.99 157.31H98.3899V176.91H117.99V157.31Z" fill="#E94591" />
                    <path d="M137.59 157.31H117.99V176.91H137.59V157.31Z" fill="#E94591" />
                    <path d="M157.18 157.31H137.58V176.91H157.18V157.31Z" fill="#E94591" />
                    <path d="M255.17 157.31H235.57V176.91H255.17V157.31Z" fill="#E94591" />
                    <path d="M294.36 157.31H274.76V176.91H294.36V157.31Z" fill="#E94591" />
                    <path d="M313.96 157.31H294.36V176.91H313.96V157.31Z" fill="#E94591" />
                    <path d="M372.75 157.31H353.15V176.91H372.75V157.31Z" fill="#E94591" />
                    <path d="M431.54 157.31H411.94V176.91H431.54V157.31Z" fill="#E94591" />
                    <path d="M470.73 157.31H451.13V176.91H470.73V157.31Z" fill="#E94591" />
                    <path d="M490.33 157.31H470.73V176.91H490.33V157.31Z" fill="#E94591" />
                    <path d="M39.5999 176.91H19.9999V196.51H39.5999V176.91Z" fill="#E94591" />
                    <path d="M59.1999 176.91H39.5999V196.51H59.1999V176.91Z" fill="#E94591" />
                    <path d="M78.7999 176.91H59.1999V196.51H78.7999V176.91Z" fill="#E94591" />
                    <path d="M98.3899 176.91H78.7899V196.51H98.3899L98.3899 176.91Z" fill="#E94591" />
                    <path d="M157.18 176.91H137.58V196.51H157.18V176.91Z" fill="#E94591" />
                    <path d="M196.38 176.91H176.78V196.51H196.38V176.91Z" fill="#E94591" />
                    <path d="M215.97 176.91H196.37V196.51H215.97V176.91Z" fill="#E94591" />
                    <path d="M235.57 176.91H215.97V196.51H235.57V176.91Z" fill="#E94591" />
                    <path d="M255.17 176.91H235.57V196.51H255.17V176.91Z" fill="#E94591" />
                    <path d="M294.36 176.91H274.76V196.51H294.36V176.91Z" fill="#E94591" />
                    <path d="M333.55 176.91H313.95V196.51H333.55V176.91Z" fill="#E94591" />
                    <path d="M392.34 176.91H372.74V196.51H392.34V176.91Z" fill="#E94591" />
                    <path d="M470.73 176.91H451.13V196.51H470.73V176.91Z" fill="#E94591" />
                    <path d="M20.0099 196.51H0.409912V216.11H20.0099V196.51Z" fill="#E94591" />
                    <path d="M98.3899 196.51H78.7899V216.11H98.3899V196.51Z" fill="#E94591" />
                    <path d="M117.99 196.51L98.3899 196.51V216.11H117.99V196.51Z" fill="#E94591" />
                    <path d="M137.59 196.51H117.99V216.11H137.59V196.51Z" fill="#E94591" />
                    <path d="M215.97 196.51H196.37V216.11H215.97V196.51Z" fill="#E94591" />
                    <path d="M235.57 196.51H215.97V216.11H235.57V196.51Z" fill="#E94591" />
                    <path d="M274.76 196.51L255.16 196.51V216.11H274.76V196.51Z" fill="#E94591" />
                    <path d="M294.36 196.51H274.76V216.11H294.36V196.51Z" fill="#E94591" />
                    <path d="M333.55 196.51H313.95V216.11H333.55V196.51Z" fill="#E94591" />
                    <path d="M353.15 196.51L333.55 196.51V216.11H353.15V196.51Z" fill="#E94591" />
                    <path d="M20.0099 216.1H0.409912V235.7H20.0099V216.1Z" fill="#E94591" />
                    <path d="M59.1999 216.1H39.5999V235.7H59.1999V216.1Z" fill="#E94591" />
                    <path d="M157.18 216.1H137.58V235.7H157.18V216.1Z" fill="#E94591" />
                    <path d="M196.38 216.1H176.78V235.7H196.38V216.1Z" fill="#E94591" />
                    <path d="M215.97 216.1H196.37V235.7H215.97V216.1Z" fill="#E94591" />
                    <path d="M274.76 216.1H255.16V235.7H274.76V216.1Z" fill="#E94591" />
                    <path d="M294.36 216.1H274.76V235.7H294.36V216.1Z" fill="#E94591" />
                    <path d="M333.55 216.1H313.95V235.7H333.55V216.1Z" fill="#E94591" />
                    <path d="M353.15 216.1H333.55V235.7H353.15V216.1Z" fill="#E94591" />
                    <path d="M431.54 216.1H411.94V235.7H431.54V216.1Z" fill="#E94591" />
                    <path d="M451.13 216.1H431.53V235.7H451.13V216.1Z" fill="#E94591" />
                    <path d="M20.0099 235.7H0.409912V255.3H20.0099V235.7Z" fill="#E94591" />
                    <path d="M39.5999 235.7H19.9999V255.3H39.5999V235.7Z" fill="#E94591" />
                    <path d="M78.7999 235.7H59.1999V255.3H78.7999V235.7Z" fill="#E94591" />
                    <path d="M98.3899 235.7H78.7899V255.3H98.3899V235.7Z" fill="#E94591" />
                    <path d="M137.59 235.7H117.99V255.3H137.59V235.7Z" fill="#E94591" />
                    <path d="M196.38 235.7H176.78V255.3H196.38V235.7Z" fill="#E94591" />
                    <path d="M235.57 235.7H215.97V255.3H235.57V235.7Z" fill="#E94591" />
                    <path d="M274.76 235.7H255.16V255.3H274.76V235.7Z" fill="#E94591" />
                    <path d="M294.36 235.7H274.76V255.3H294.36V235.7Z" fill="#E94591" />
                    <path d="M353.15 235.7H333.55V255.3H353.15V235.7Z" fill="#E94591" />
                    <path d="M372.75 235.7H353.15V255.3H372.75V235.7Z" fill="#E94591" />
                    <path d="M411.94 235.7H392.34V255.3H411.94V235.7Z" fill="#E94591" />
                    <path d="M451.13 235.7H431.53V255.3H451.13V235.7Z" fill="#E94591" />
                    <path d="M470.73 235.7H451.13V255.3H470.73V235.7Z" fill="#E94591" />
                    <path d="M490.33 235.7H470.73V255.3H490.33V235.7Z" fill="#E94591" />
                    <path d="M39.5999 255.3H19.9999V274.9H39.5999V255.3Z" fill="#E94591" />
                    <path d="M98.3899 255.3H78.7899V274.9H98.3899V255.3Z" fill="#E94591" />
                    <path d="M117.99 255.3H98.3899V274.9H117.99V255.3Z" fill="#E94591" />
                    <path d="M215.97 255.3H196.37V274.9H215.97V255.3Z" fill="#E94591" />
                    <path d="M235.57 255.3H215.97V274.9H235.57V255.3Z" fill="#E94591" />
                    <path d="M294.36 255.3H274.76V274.9H294.36V255.3Z" fill="#E94591" />
                    <path d="M313.96 255.3H294.36V274.9H313.96V255.3Z" fill="#E94591" />
                    <path d="M333.55 255.3H313.95V274.9H333.55V255.3Z" fill="#E94591" />
                    <path d="M372.75 255.3H353.15V274.9H372.75V255.3Z" fill="#E94591" />
                    <path d="M392.34 255.3H372.74V274.9H392.34V255.3Z" fill="#E94591" />
                    <path d="M411.94 255.3H392.34V274.9H411.94V255.3Z" fill="#E94591" />
                    <path d="M490.33 255.3H470.73V274.9H490.33V255.3Z" fill="#E94591" />
                    <path d="M39.5999 274.89H19.9999V294.49H39.5999V274.89Z" fill="#E94591" />
                    <path d="M137.59 274.89H117.99V294.49H137.59V274.89Z" fill="#E94591" />
                    <path d="M157.18 274.89H137.58V294.49H157.18V274.89Z" fill="#E94591" />
                    <path d="M255.17 274.89H235.57V294.49H255.17V274.89Z" fill="#E94591" />
                    <path d="M274.76 274.89H255.16V294.49H274.76V274.89Z" fill="#E94591" />
                    <path d="M294.36 274.89H274.76V294.49H294.36V274.89Z" fill="#E94591" />
                    <path d="M411.94 274.89H392.34V294.49H411.94V274.89Z" fill="#E94591" />
                    <path d="M451.13 274.89H431.53V294.49H451.13V274.89Z" fill="#E94591" />
                    <path d="M470.73 274.89H451.13V294.49H470.73V274.89Z" fill="#E94591" />
                    <path d="M20.0099 294.49H0.409912V314.09H20.0099V294.49Z" fill="#E94591" />
                    <path d="M59.1999 294.49H39.5999V314.09H59.1999V294.49Z" fill="#E94591" />
                    <path d="M98.3899 294.49H78.7899V314.09H98.3899V294.49Z" fill="#E94591" />
                    <path d="M117.99 294.49H98.3899V314.09H117.99V294.49Z" fill="#E94591" />
                    <path d="M196.38 294.49H176.78V314.09H196.38V294.49Z" fill="#E94591" />
                    <path d="M255.17 294.49H235.57V314.09H255.17V294.49Z" fill="#E94591" />
                    <path d="M294.36 294.49H274.76V314.09H294.36V294.49Z" fill="#E94591" />
                    <path d="M313.96 294.49H294.36V314.09H313.96V294.49Z" fill="#E94591" />
                    <path d="M333.55 294.49H313.95V314.09H333.55V294.49Z" fill="#E94591" />
                    <path d="M392.34 294.49H372.74V314.09H392.34V294.49Z" fill="#E94591" />
                    <path d="M411.94 294.49H392.34V314.09H411.94V294.49Z" fill="#E94591" />
                    <path d="M490.33 294.49H470.73V314.09H490.33V294.49Z" fill="#E94591" />
                    <path d="M137.59 314.09H117.99V333.69H137.59V314.09Z" fill="#E94591" />
                    <path d="M157.18 314.09H137.58V333.69H157.18V314.09Z" fill="#E94591" />
                    <path d="M176.78 314.09H157.18V333.69H176.78V314.09Z" fill="#E94591" />
                    <path d="M196.38 314.09H176.78V333.69H196.38V314.09Z" fill="#E94591" />
                    <path d="M215.97 314.09H196.37V333.69H215.97V314.09Z" fill="#E94591" />
                    <path d="M235.57 314.09H215.97V333.69H235.57V314.09Z" fill="#E94591" />
                    <path d="M274.76 314.09H255.16V333.69H274.76V314.09Z" fill="#E94591" />
                    <path d="M294.36 314.09H274.76V333.69H294.36V314.09Z" fill="#E94591" />
                    <path d="M333.55 314.09H313.95V333.69H333.55V314.09Z" fill="#E94591" />
                    <path d="M353.15 314.09H333.55V333.69H353.15V314.09Z" fill="#E94591" />
                    <path d="M372.75 314.09H353.15V333.69H372.75V314.09Z" fill="#E94591" />
                    <path d="M392.34 314.09H372.74V333.69H392.34V314.09Z" fill="#E94591" />
                    <path d="M411.94 314.09H392.34V333.69H411.94V314.09Z" fill="#E94591" />
                    <path d="M431.54 314.09H411.94V333.69H431.54V314.09Z" fill="#E94591" />
                    <path d="M451.13 314.09H431.53V333.69H451.13V314.09Z" fill="#E94591" />
                    <path d="M470.73 314.09H451.13V333.69H470.73V314.09Z" fill="#E94591" />
                    <path d="M490.33 314.09H470.73V333.69H490.33V314.09Z" fill="#E94591" />
                    <path d="M176.78 333.68H157.18V353.28H176.78V333.68Z" fill="#E94591" />
                    <path d="M196.38 333.68H176.78V353.28H196.38V333.68Z" fill="#E94591" />
                    <path d="M255.17 333.68H235.57V353.28H255.17V333.68Z" fill="#E94591" />
                    <path d="M313.96 333.68H294.36V353.28H313.96V333.68Z" fill="#E94591" />
                    <path d="M333.55 333.68H313.95V353.28H333.55V333.68Z" fill="#E94591" />
                    <path d="M411.94 333.68H392.34V353.28H411.94V333.68Z" fill="#E94591" />
                    <path d="M451.13 333.68H431.53V353.28H451.13V333.68Z" fill="#E94591" />
                    <path d="M490.33 333.68H470.73V353.28H490.33V333.68Z" fill="#E94591" />
                    <path d="M176.78 353.28H157.18V372.88H176.78V353.28Z" fill="#E94591" />
                    <path d="M196.38 353.28H176.78V372.88H196.38V353.28Z" fill="#E94591" />
                    <path d="M215.97 353.28H196.37V372.88H215.97V353.28Z" fill="#E94591" />
                    <path d="M235.57 353.28H215.97V372.88H235.57V353.28Z" fill="#E94591" />
                    <path d="M255.17 353.28H235.57V372.88H255.17V353.28Z" fill="#E94591" />
                    <path d="M274.76 353.28H255.16V372.88H274.76V353.28Z" fill="#E94591" />
                    <path d="M294.36 353.28H274.76V372.88H294.36V353.28Z" fill="#E94591" />
                    <path d="M333.55 353.28H313.95V372.88H333.55V353.28Z" fill="#E94591" />
                    <path d="M372.75 353.28H353.15V372.88H372.75V353.28Z" fill="#E94591" />
                    <path d="M411.94 353.28H392.34V372.88H411.94V353.28Z" fill="#E94591" />
                    <path d="M451.13 353.28H431.53V372.88H451.13V353.28Z" fill="#E94591" />
                    <path d="M470.73 353.28H451.13V372.88H470.73V353.28Z" fill="#E94591" />
                    <path d="M490.33 353.28H470.73V372.88H490.33V353.28Z" fill="#E94591" />
                    <path d="M176.78 372.88H157.18V392.48H176.78V372.88Z" fill="#E94591" />
                    <path d="M196.38 372.88H176.78V392.48H196.38V372.88Z" fill="#E94591" />
                    <path d="M255.17 372.88H235.57V392.48H255.17V372.88Z" fill="#E94591" />
                    <path d="M313.96 372.88H294.36V392.48H313.96V372.88Z" fill="#E94591" />
                    <path d="M333.55 372.88H313.95V392.48H333.55V372.88Z" fill="#E94591" />
                    <path d="M411.94 372.88H392.34V392.48H411.94V372.88Z" fill="#E94591" />
                    <path d="M470.73 372.88H451.13V392.48H470.73V372.88Z" fill="#E94591" />
                    <path d="M490.33 372.88H470.73V392.48H490.33V372.88Z" fill="#E94591" />
                    <path d="M235.57 392.47H215.97V412.07H235.57V392.47Z" fill="#E94591" />
                    <path d="M255.17 392.47H235.57V412.07H255.17V392.47Z" fill="#E94591" />
                    <path d="M274.76 392.47H255.16V412.07H274.76V392.47Z" fill="#E94591" />
                    <path d="M333.55 392.47H313.95V412.07H333.55V392.47Z" fill="#E94591" />
                    <path d="M353.15 392.47H333.55V412.07H353.15V392.47Z" fill="#E94591" />
                    <path d="M372.75 392.47H353.15V412.07H372.75V392.47Z" fill="#E94591" />
                    <path d="M392.34 392.47H372.74V412.07H392.34V392.47Z" fill="#E94591" />
                    <path d="M411.94 392.47H392.34V412.07H411.94V392.47Z" fill="#E94591" />
                    <path d="M431.54 392.47H411.94V412.07H431.54V392.47Z" fill="#E94591" />
                    <path d="M470.73 392.47H451.13V412.07H470.73V392.47Z" fill="#E94591" />
                    <path d="M176.78 412.07H157.18V431.67H176.78V412.07Z" fill="#E94591" />
                    <path d="M255.17 412.07H235.57V431.67H255.17V412.07Z" fill="#E94591" />
                    <path d="M274.76 412.07H255.16V431.67H274.76V412.07Z" fill="#E94591" />
                    <path d="M372.75 412.07H353.15V431.67H372.75V412.07Z" fill="#E94591" />
                    <path d="M411.94 412.07H392.34V431.67H411.94V412.07Z" fill="#E94591" />
                    <path d="M431.54 412.07H411.94V431.67H431.54V412.07Z" fill="#E94591" />
                    <path d="M451.13 412.07H431.53V431.67H451.13V412.07Z" fill="#E94591" />
                    <path d="M470.73 412.07H451.13V431.67H470.73V412.07Z" fill="#E94591" />
                    <path d="M490.33 412.07H470.73V431.67H490.33V412.07Z" fill="#E94591" />
                    <path d="M176.78 431.67H157.18V451.27H176.78V431.67Z" fill="#E94591" />
                    <path d="M235.57 431.67H215.97V451.27H235.57V431.67Z" fill="#E94591" />
                    <path d="M255.17 431.67H235.57V451.27H255.17V431.67Z" fill="#E94591" />
                    <path d="M274.76 431.67H255.16V451.27H274.76V431.67Z" fill="#E94591" />
                    <path d="M353.15 431.67H333.55V451.27H353.15V431.67Z" fill="#E94591" />
                    <path d="M372.75 431.67H353.15V451.27H372.75V431.67Z" fill="#E94591" />
                    <path d="M411.94 431.67H392.34V451.27H411.94V431.67Z" fill="#E94591" />
                    <path d="M451.13 431.67H431.53V451.27H451.13V431.67Z" fill="#E94591" />
                    <path d="M470.73 431.67H451.13V451.27H470.73V431.67Z" fill="#E94591" />
                    <path d="M313.96 451.26H294.36V470.86H313.96V451.26Z" fill="#E94591" />
                    <path d="M353.15 451.26H333.55V470.86H353.15V451.26Z" fill="#E94591" />
                    <path d="M372.75 451.26H353.15V470.86H372.75V451.26Z" fill="#E94591" />
                    <path d="M411.94 451.26H392.34V470.86H411.94V451.26Z" fill="#E94591" />
                    <path d="M451.13 451.26H431.53V470.86H451.13V451.26Z" fill="#E94591" />
                    <path d="M176.78 470.86H157.18V490.46H176.78V470.86Z" fill="#E94591" />
                    <path d="M215.97 470.86H196.37V490.46H215.97V470.86Z" fill="#E94591" />
                    <path d="M255.17 470.86H235.57V490.46H255.17V470.86Z" fill="#E94591" />
                    <path d="M294.36 470.86H274.76V490.46H294.36V470.86Z" fill="#E94591" />
                    <path d="M392.34 470.86H372.74V490.46H392.34V470.86Z" fill="#E94591" />
                    <path d="M411.94 470.86H392.34V490.46H411.94V470.86Z" fill="#E94591" />
                    <path d="M431.54 470.86H411.94V490.46H431.54V470.86Z" fill="#E94591" />
                    <path d="M451.13 470.86H431.53V490.46H451.13V470.86Z" fill="#E94591" />
                    <path d="M470.73 470.86H451.13V490.46H470.73V470.86Z" fill="#E94591" />
                    <path d="M490.33 470.86H470.73V490.46H490.33V470.86Z" fill="#E94591" />
                    <path
                        d="M0.409912 0.539978V137.72H137.59V0.539978H0.409912ZM117.01 117.14L20.9799 117.14V21.12H117V117.14L117.01 117.14Z"
                        fill="#E94591" />
                    <path d="M353.15 0.539978V137.72H490.33V0.539978H353.15ZM469.75 117.14H373.73V21.12H469.75V117.14Z"
                        fill="#E94591" />
                    <path
                        d="M0.409912 353.28V490.46H137.59V353.28H0.409912ZM117.01 469.88H20.9799V373.86H117V469.88H117.01Z"
                        fill="#E94591" />
                    <path d="M98.3899 39.73H39.5999V98.52H98.3899V39.73Z" fill="#E94591" />
                    <path d="M451.13 39.73H392.34V98.52H451.13V39.73Z" fill="#E94591" />
                    <path d="M98.3899 392.47H39.5999V451.26H98.3899V392.47Z" fill="#E94591" />
                </svg>


            </div>
        </div>

    </div>



</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
    name: 'ResultsScreen',
    setup() {

        const store = useStore()
        const leaderboard = computed(() => store.state.leaderboard)
        const acfData = ref([])
        const videoRef = ref(null)

        function getACFData() {
            acfData.value = []

            fetch('/wp-json/custom/v1/acf-options/')
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data) {
                        acfData.value = data
                    }
                })
                .catch((error) => {
                    console.error('error:', error)
                })
        }

        function getLeaderBoard() {
            var data = {
                action: 'GetLeaderBoard',
            }

            fetch(ajaxUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: new URLSearchParams(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.success) {
                        store.commit('leaderboard', data.leaderboard)
                    }
                })
                .catch((error) => {
                    console.error('error:', error)
                })
        }

        const onVideoEnded = () => {
            getACFData()
        };

        watch(videoRef, (newData) => {
            if (newData) {
                console.log(videoRef.value)
                videoRef.value.addEventListener('ended', onVideoEnded, false)
            }
        })

        onMounted(() => {

            getACFData()
            watch(acfData, (newData) => {
                if (newData.video && videoRef.value) {
                    videoRef.value.play().catch(error => {
                        console.error('Autoplay failed:', error)
                    })
                }
            })
            getLeaderBoard()

            setInterval(() => {
                getLeaderBoard()
            }, 5000)

        })

        return {
            leaderboard,
            acfData,
            videoRef
        }
    }
}
</script>

<style scoped>
.tv-wrapper {
    @apply w-[90%] mx-auto;
}

.tv-heading {
    font-size: calc(32px + 0.5vw);
    line-height: 1.1;
    font-weight: 500;
}

.current-leader-text {
    font-size: calc(46px + 0.5vw);
    line-height: 1.1;
    font-weight: 500;
    @apply text-darkPurple mb-[2%];
}

.leader-text {
    font-size: calc(66px + 1vw);
    line-height: 1.1;
    font-weight: 500;
}

.name-highlight {
    @apply flex flex-row items-center justify-center gap-[2%];
}

.name-highlight svg {
    position: relative;
    margin-top: -5%;
}


.tv-text {
    font-size: calc(16px + 0.5vw);
    line-height: 1.6;
}
</style>