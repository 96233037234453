// Our main CSS
import '../css/app.css'
import App from './App.vue'
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from './store/index.js'

import StartScreen from './components/StartScreen.vue'
import QuestionScreen from './components/QuestionScreen.vue'
import RegisterScreen from './components/RegisterScreen.vue'
import ResultScreen from './components/ResultScreen.vue'
import ResultsScreen from './components/ResultsScreen.vue'

const routes = [
    { path: '/', name: 'start', component: StartScreen },
    { path: '/questions', name: 'questions', component: QuestionScreen },
    { path: '/register', name: 'register', component: RegisterScreen },
    { path: '/result', name: 'result', component: ResultScreen },
    { path: '/results', name: 'results', component: ResultsScreen }
]

// Create the router instance
const router = createRouter({
    history: createWebHistory(),
    routes
})

const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')
