<template>
    <div class="px-5 py-8 wrapper">
        <svg class="mx-auto mb-16" width="80" height="39" viewBox="0 0 80 39" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_6)">
                <path
                    d="M28.9754 0.257179H0V38.9853H6.7021V6.64608H26.0203L28.9754 0.257179ZM36.0531 0.00253931L27.0198 19.9752H12.0661V26.3781H24.1194L18.4117 38.9827H25.4792L31.108 26.3781H40.6633L37.8088 19.9764H33.9701L38.7523 9.28159L51.9273 38.9853H59.2023L41.5648 -7.62939e-06L36.0531 0.00253931ZM79.9987 13.1636V13.0617C79.9987 9.68774 78.7255 6.14826 76.8297 4.25247C74.2515 1.67552 70.2766 0.257179 65.6396 0.257179H48.4095L51.3378 6.67154L65.3328 6.6499C70.1327 6.66899 73.2431 9.24849 73.2533 13.2145V13.3189C73.2533 17.4046 70.3988 19.951 65.8191 19.9663H60.9428H57.2887L60.2171 26.3806H64.3613L71.4059 38.9853H79.4971L71.4798 25.073C76.7814 23.4357 80.0076 18.9884 79.9987 13.1636Z"
                    fill="#710666" />
            </g>
            <defs>
                <clipPath id="clip0_1_6">
                    <rect width="80" height="38.9853" fill="white" />
                </clipPath>
            </defs>
        </svg>
        <div>
            <h1 class="heading-xl text-purple text-center mb-4 shadowed-text">Smartast på järvaveckan - har du vad som
                krävs?</h1>
            <p class="body-md text-darkPurple text-center">Är du redo att sätta din intelligens på prov?
                Nu har du chansen att skaffa bragging rights genom att plocka hem titeln: </p>
            <p class="body-lg text-darkPurple text-center shadowed-text">Smartast på Järvaveckan.</p>
            <svg class="mx-auto mb-8" width="192" height="12" viewBox="0 0 192 12" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H191L21.0962 9.5L155.071 11" stroke="#710666" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </div>

        <div class="bg-pink p-6 rounded-2xl -rotate-[4deg] mb-8">
            <p class="body-lg text-yellow text-center">Testet går ut på att svara på 10 frågor med så många rätt och på
                så
                kort tid som
                möjligt. Tar
                du dig
                upp på vår
                highscorelista? Lycka till!</p>
        </div>
        <button @click="startQuiz" class="button">
            Sätt igång
            <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.2633 7.53826H0.399475V5.89987H17.2632L12.9273 1.56397L14.0859 0.405457L20.3995 6.71908L14.0859 13.0327L12.9273 11.8742L17.2633 7.53826Z"
                    fill="#FBF5BE" />
            </svg>
        </button>
    </div>

</template>

<script>
export default {
    name: 'StartScreen',
    methods: {
        startQuiz() {
            this.$store.dispatch('updateTransition', 'slide-right')
            this.$router.push({ name: 'questions' })
        }
    }
}
</script>
