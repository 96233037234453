<template>
    <div class="app-container">
        <router-view v-slot="{ Component }">
            <transition :name="transitionName">
                <component :is="Component" :key="$route.fullPath" class="view" />
            </transition>
        </router-view>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'App',
    computed: {
        ...mapGetters(['transitionName'])
    }
}
</script>

<style>
.app-container {
    @apply relative w-full min-h-[100%];
}

.view {
    @apply absolute w-full min-h-[100%] bg-yellow z-[999999] md:mx-auto md:static;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
    transition: all 0.5s ease;
    z-index: 1;
}

.slide-left-enter,
.slide-left-leave-to {
    transform: translateX(100%);

}

.slide-left-leave-active {
    position: absolute;
    z-index: 2;
}

.slide-right-enter,
.slide-right-leave-to {
    transform: translateX(-100%);
    z-index: 99999;
}

.slide-right-leave-active {
    position: absolute;
}
</style>
