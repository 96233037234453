<template>
  <div class="flex flex-row justify-between items-center h-[24px] mb-2.5">
    <div v-for="dot in total" :key="dot" class="dot">
      <svg v-if="dot - 1 !== progress" width="14" height="14" viewBox="0 0 14 14" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="7" cy="7" r="7" fill="#7C5FC4" />
      </svg>
      <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
          stroke="#FF48B0" stroke-width="2" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
}
</script>

<style></style>
